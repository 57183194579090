function isStyle(type: number){
    if(type === 1) {
        return 'underline'
    } else {
        return ''
    }
}

function ChecklistDom(props: any) {
    const {data} = props
    return (
        <ul className='item-check-list'>
            {
                data.children.map((item: any, index: number) => {
                    return (
                        <li className='first-check-list' key={index}>
                            <div className={`first-content ${isStyle(item.style)}`}>{item.content}</div>
                            {
                                item.hasOwnProperty('children') ? (
                                    <ul className='second-check-list'>
                                        {
                                            item.children.map((item: any, index: number) => {
                                                return (
                                                    <li className='second-content' key={index}>{item}</li>
                                                )
                                            })
                                        }
                                    </ul>
                                ) : null
                            }
                        </li>
                    )
                })
            }
        </ul>
    )
}

export default ChecklistDom