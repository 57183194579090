function isStyle(type: number) {
    if(type === 1) {
        return 'underline'
    } else {
        return ''
    }
}
 
function ParagraphDom(props: any) {
    const {data} = props
    return (
        <div className='item-paragrap'>
            {
                data.children.map((item: any, index: any) => {
                    return (
                        <div className={`paragrap-content ${isStyle(item.style)}`} key={index}>{item.content}</div>
                    )
                })
            }
        </div>
    )
}

export default ParagraphDom