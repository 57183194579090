import React from 'react'
import Greeting from './modules/greeting'
import './index.scss'

interface IsPageStateType {
    title: string,
    protocolList: any,
}

interface IsPagePropsType {
    
}

class Index extends React.Component<IsPagePropsType, IsPageStateType> {
    public state = {
        title: '“佳联亿通”小程序使用协议',
        /**
         * @param {number} type = 类型
         * @example
         *  type = 0 段落
         *  type = 1 文档 
         */
        protocolList: [
            {
                title: '法律声明',
                type: 0,
                children: [
                    {
                        content: '访问、浏览或使用“佳联亿通”小程序，表明您已经阅读、理解并同意接受以下条款的约束，并遵守所有适用的法律和法规。您一旦使用“佳联亿通”小程序，则须秉着诚信的原则遵守以下条款。',
                        style: 0,
                    }
                ]
            },
            {
                title: '一般原则',
                type: 1,
                children: [
                    {
                        content: '您同意您在使用“佳联亿通”小程序服务时接受本协议的约束。',
                        style: 0,
                    },
                    {
                        content: '本协议条款及条件中，除文意另有所指外，以下词句应作如下解释：',
                        style: 0,
                        children: [
                            '“服务车辆”分别指与“佳联亿通”以及北京佳联亿通货物运输有限公司达成协议，根据您通过“佳联亿通”小程序下达订单并按订单内容提供运输服务的车辆；',
                            '有关服务',
                            '广告指“佳联亿通”小程序运行时会在您运行应用程序额移动终端出现的推广信息；'
                        ]
                    },
                    {
                        content: '本协议可能因业务政策调整而被调整或修改。您应经常访问本页面以了解当前的条款，因为这些条款与您密切相关。这些条款的某些条文也可能被“佳联亿通”小程序中某些页面上或某些具体服务明确指定的法律通告或条款所取代，您应该了解这些内容，一旦接受本条款，即意味着您已经同时详细阅读并接受了这些被引用或取代的条款。',
                        style: 0,
                    }
                ]
            },
            {
                title: '权利说明',
                type: 1,
                children: [
                    {
                        content: '您因使用“佳联亿通”小程序而被“佳联亿通”获准本小程序的特许使用权',
                        style: 0,
                    },
                    {
                        content: '除非另有条款说明，否则“佳联亿通”小程序对其发行的或与关联公司、合作公司共同发行的包括但不限于提供的软件及相关产品或服务的全部内容，享有知识产权，受相关法律的保护。',
                        style: 0,
                    },
                    {
                        content: '未经“佳联亿通”小程序书面许可，您不得以任何方式或理由对“佳联亿通”小程序的应用程序的任何部分进行使用、复制、修改、抄录、进行逆向工程、反编译、解析、翻译本应用程序以及以本应用程序为基础创作的衍生服务或产品。',
                        style: 0,
                    },
                    {
                        content: '您在使用“佳联亿通”小程序服务过程中不得上传或发放任何形式的病毒、蠕虫、木马、恶意代码；',
                        style: 0,
                    },
                    {
                        content: '本应用程序中使用和显示的商标和表示是“佳联亿通”小程序及其所代表的运营主体或关联公司内注册或被授权使用的有关商标，受法律保护。未经“佳联亿通”小程序书面同意，任何人不得以任何方式使用“佳联亿通”小程序及近似名称和相关商标、标识。',
                        style: 0,
                    }
                ]
            },
            {
                title: '责任条款',
                type: 0,
                children: [
                    {
                        content: '“佳联亿通”小程序将尽力保证有关服务的供应、通过使用本应用程序所获得的车辆供应、作为其他相关服务所提供的数据及数据的准确性、或参与“佳联亿通”小程序提供的服务的依据。',
                        style: 0,
                    },
                    {
                        content: '“佳联亿通”小程序仅负责为您的货运需求信息与实际服务提供方提供中立的信息匹配服务，您理解并同意，“佳联亿通”小程序并非为您提供货物运输服务商或货物运输第三方代理方，为您提供货物运输服务的承运人也并未以任何形式与“佳联亿通”小程序形成雇佣关系。',
                        style: 1,
                    },
                    {
                        content: '无论在任何情况下，“佳联亿通”小程序对由于信息网络设备维护、信息网络连接故障、智能终端、通讯或其他系统的故障、电力故障、罢工、劳动争议、暴乱、起义、骚乱、火灾、洪水、风暴、爆炸、战争、政府行为、司法行政机关的命令、其他不可抗力或第三方的不作为而造成的不能服务或延迟服务始终不承担责任。',
                        style: 0,
                    },
                    {
                        content: '需要您特别重视的是：在“佳联亿通”小程序为您提供相关服务信息后，您需要对服务提供方进行适当的审查义务，并作出是否接受其为您提供服务的决定。您需要按照《中华人民共和国合同法》的规定对标的物进行适当包装以方便服务提供方提供服务并在法律范围内承担整个服务过程中的法律责任。',
                        style: 0,
                    },
                    {
                        content: '需要您重点注意的是：根据中华人民共和国法律法规，您所托运的标的不得含有禁运物、危险品或同性质的物品。如有禁运物或危险品，您需提前告知服务提供方以及“佳联亿通”小程序，“佳联亿通”小程序及服务提供方有权拒绝为您提供服务。',
                        style: 0,
                    },
                    {
                        content: '需要您特别注意的是：您在使用“佳联亿通”小程序服务的过程中不得影响本应用程序的正常运行以及牟取非法利益，包括但不限于：发布虚假订单、利用订单发布任何形式的广告等。“佳联亿通”小程序如检测到您有类似行为的，将认定您非法使用本应用程序，并将合并/单独采取以下措施的一种或多种：',
                        style: 0,
                        children: [
                            { content: '冻结您的用户账号；' },
                            { content: '暂时冻结您涉嫌违规的金额。' },
                        ]
                    },
                    {
                        content: '同时，“佳联亿通”小程序将会启动人工核查机制来审查您的行为。您可能需要因此承担相应的法律责任并赔偿相应的损失。',
                        style: 0,
                    }
                ]
            },
            {
                title: '使用守则',
                type: 1,
                children: [
                    {
                        content: '您不得利用“佳联亿通”小程序进行或推广任何非法活动；',
                        style: 0,
                    },
                    {
                        content: '您不得使用“佳联亿通”小程序跟踪、骚扰或伤害任何人；',
                        style: 0,
                    },
                    {
                        content: '您对您所拥有的账号所产生的一切行为承担法律责任。',
                        style: 0,
                    },
                    {
                        content: '您清楚并同意，您在“佳联亿通”小程序下单后，由您将服务相关费用直接提供给运输服务的承运人，本小程序并不参与您们的支付交易过程。',
                        style: 1,
                    }
                ]
            },
            {
                title: '适用法律和管辖权',
                type: 0,
                children: [
                    {
                        content: '通过访问“佳联亿通”小程序使用其提供的服务或其合作服务商提供的服务，即表示您同意该访问或服务受中华人民共和国法律的约束，且您同意受中华人民共和国法院的管辖。',
                        style: 0,
                    },
                    {
                        content: '您在访问或接受服务过程中发生的争议应当协商解决，协商不成的，您同意接受“佳联亿通”小程序运营主体公司所在地北京市有管辖权的人民法院管辖。',
                        style: 0,
                    }
                ]
            },
        ]
    }

    componentWillMount(){
        document.title = '佳联亿通-注册服务协议'
    }

    public render() {
        return (
            <div className='registered'>
                <div className='registered-container'>
                    <header className='registered-header'>
                        <h2 className='registered-title'>{this.state.title}</h2>
                    </header>
                    <div className='registered-main'>
                        {
                            this.state.protocolList.map((termsItem: any, termsIndex: number) => {
                                return (
                                    <div className='terms-item' key={termsIndex}>
                                        <h3 className='terms-title'>{termsItem.title}</h3>
                                        {
                                            <Greeting termsType={termsItem.type} termsItem={termsItem} />
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default Index