import React from 'react'

interface IsPageStateType {

}

interface IsPagePropsType {}

class Index extends React.Component<IsPagePropsType, IsPageStateType> {
    public state = {

    }

    public render(){
        return (
            <div className='merchant-settled'>
                <div className="merchant-settled-container">
                    <header className="merchant-settled-header">
                        <h2 className='merchant-settled-title'></h2>
                    </header>
                    <main className="merchant-settled-main">

                    </main>
                </div>
            </div>
        )
    }
}

export default Index