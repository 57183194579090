import React from 'react'
import {
	BrowserRouter as Router,
	Switch,
	Route
} from 'react-router-dom'
import RegistrationAgreement from './views/registrationAgreement/index'
import MerchantSettled from './views/merchantSettled/index'
import './App.css'

export default class App extends React.Component {
	render() {
		return (
			<div className='app'>
				<Router>
					<Switch>
						<Route exact path="/registered">
							<RegistrationAgreement />
						</Route>
						<Route exact path="/settleIn">
							<MerchantSettled />
						</Route>
					</Switch>
				</Router>
			</div>
		)
	}
}