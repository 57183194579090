import ParagraphDom from './paragraphDom'
import ChecklistDom from './checklistDom'

interface GreetingPropsType {
    termsType: number,
    termsItem: any
}

function Greeting(props: GreetingPropsType) {
    const {termsType, termsItem} = props
    if (termsType === 0) {
        return <ParagraphDom data={termsItem} />
    } else if (termsType === 1) {
        return <ChecklistDom data={termsItem} />
    } else {
        return (<div>未找到</div>)
    }
}

export default Greeting